@import './variables.scss';


.icon{
  padding: 5px;
}
#myWork{
  color: $light-color;
  text-shadow: 1px 1px $dark-color;
}
.workImg {
    width: 100%;
  }
  
  #workContainer {
    display: flex;
    flex-direction: row;
    color: $dark-color;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  
  .workDetails {
    display: flex;
    flex-direction: column;
  }
  
  
  .workPortfolio {
    border: 0.2rem solid $light-color;
    width: 30rem;
    margin: 1rem;
    background-color: $work-background;
    padding: 0.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0.5;
  }
  
  .workPortfolio:hover {
    transition-duration: 1.5s;
    opacity: 0.9;
    box-shadow: 0 0 10px $dark-color;
  }
  