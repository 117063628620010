@import "./variables.scss";

#footer {
  width: 100%;
  height: auto;
  word-wrap: break-word;
  background-color: $faq;
  color: $primary-color;
  text-align: center;
  font-size: 1.5rem;
  bottom: 0;
  position: fixed;
}

.footerDetails {
  height: 3.5rem;
}

.floatRight {
  float: right;
  margin: 15px;
}
