@import './variables.scss';

#heroContactPic {
    width: 10rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
    box-shadow: 0 0 10px $dark-color;
    border-radius: 2px;
  }
  
  .heroSection {
    color: $light-color;
    height: 37%;
    padding: 30px;
  }
  
  #heroText2 {
    font-size: 4rem;
    text-align: center;
    text-shadow: 0 0 10px $dark-color;
   
  }
  
  #heroTitle {
    font-size: 0.5em;
    margin-top: -1rem;
    margin-bottom: -5rem;
    opacity: 0.8%;
    
  }
  
  .name {
    font-family: cursive;
  }