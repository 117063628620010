@import "./variables.scss";

.contactMeDetails {
  border: 0.2rem solid $primary-color;
  font-size: 1.5rem;
  // margin: auto;
  // margin-bottom: 0.5rem;
  // background-color: $faq;
  // // position: relative;
  // color: $light-color;
  min-width: 45%;
  max-width: 332px;
  word-wrap: break-word;
  padding: 1rem;
  margin: 10px;
  box-shadow: 0 0 10px $dark-color;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
}

.contactLine {
  display: flex;
  justify-content: space-between;
}
.contactRow {
  vertical-align: super;
  padding: 5px;
}
.contactMe {
  color: $light-color;
  font-size: 2rem;
  padding: 1rem;
  margin-bottom: 50px;
}

#contactTitle {
  text-align: center;
  padding: 1rem;
}

#stateLogo {
  display: flex;
  justify-content: center;
}

#sayKarenLogoFrontPage {
  height: 100px;
  margin-right: -33px;
}
